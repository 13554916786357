import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import Banner from './Banner';
import Carrucel from './Carrucel';
// import MarcasInicio from './MarcasInicio';
import ForoInicio from './ForoInicio';

function Inicio() {
  useEffect(() => {
    axios.get('https://onloop.cl/api/database.php')
      .then(response => {
        // console.log('Datos de la base de datos:', response.data);
      })
      .catch(error => {
        // console.error('Hubo un error al obtener los datos:', error);
        
      });
  }, []);

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid item xs={12}>
        <Banner />
      </Grid>
      <Grid item xs={12}>
        <div style={{ padding: '20px', textAlign: 'center', marginTop: '80px' }}>
        <Typography 
            variant="h3" 
            sx={{ 
              marginBottom: '20px', 
              color: '#333' 
            }}
          >
            <Link 
              to="/productos" 
              style={{ 
                textDecoration: 'none', 
                color: 'inherit', 
                fontSize: '3rem', 
                fontWeight: 700, // Negrita
                fontFamily: 'Outfit, sans-serif',
              }}
            >
              Productos
            </Link>
          </Typography>
          <Carrucel />
          {/* <Typography 
            variant="h3" 
            sx={{ 
              fontSize: '1.5rem',
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              marginTop: '80px',
              color: '#333'
            }}
          >
            Marcas
          </Typography>
          <MarcasInicio /> */}
          <Typography 
            variant="h3" 
            sx={{ 
              marginTop: '80px', 
              color: '#333' 
            }}
          >
            <Link 
              to="/blog" 
              style={{ 
                textDecoration: 'none', 
                color: 'inherit', 
                fontSize: '3rem', 
                fontWeight: 'bold', // Negrita
                fontFamily: 'Outfit, sans-serif', 
              }}
            >
              Blog
            </Link>
          </Typography>
          <ForoInicio />
        </div>
      </Grid>
    </Grid>
  );
}

export default Inicio;
