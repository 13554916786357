import React, { useContext, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CartContext } from '../../Context/CardContext';
import ReCAPTCHA from "react-google-recaptcha"; // Importar ReCAPTCHA

const SeleccionMedioPago = ({
    medioPago,
    mediosPago,
    handleMedioPagoChange,
    carrito,
    precioTotal,
    costoEnvio,
    nombre,
    rut,
    telefono,
    correo,
    direccion,
    selectedComuna,
    selectedRegion
}) => {
    const { vaciarCarrito } = useContext(CartContext);
    const [mensajeError, setMensajeError] = useState('');
    const [mensajeAdicional, setMensajeAdicional] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null); // Estado para el valor del reCAPTCHA
    const recaptchaRef = useRef(null); // Referencia para el reCAPTCHA

    // Función para verificar el stock y vigencia de los productos
    const verificarStockYVigencia = async () => {
        try {
            const response = await fetch('https://onloop.cl/api/verificar_stock.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    productos: JSON.stringify(carrito.map(producto => ({
                        id_producto: producto.id,
                        cantidad: producto.cantidad
                    })))
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error en la verificación de stock:`, response.statusText, errorText);
                throw new Error('Error en la solicitud: ' + response.statusText);
            }

            const result = await response.json();
            if (result.success) {
                return true;
            } else {
                if (result.resultados && Array.isArray(result.resultados)) {
                    const errores = result.resultados.map(producto => 
                        `El producto "${producto.titulo}" ${producto.razon}.`
                    ).join('\n');
                    setMensajeError(errores);  // Actualizar el mensaje de error con los detalles
                }
                return false;
            }
        } catch (error) {
            console.error('Error al verificar el stock:', error.message);
            setMensajeError('Error de conexión al verificar el stock');
            return false;
        }
    };

    // Función que maneja el pago
    const handlePayClick = async () => {
        const stockValido = await verificarStockYVigencia();

        if (!stockValido) {
            setMensajeAdicional('Regresarás a la página de inicio');
            setTimeout(() => {
                vaciarCarrito();
                window.location.href = '/';
            }, 10000); // 10 segundos de espera antes de redirigir
            return;
        }

        // Si el stock es válido, proceder a guardar los datos en localStorage
        const productos = carrito.map(producto => ({
            id: producto.id,
            titulo: producto.titulo,
            precio: producto.precio,
            cantidad: producto.cantidad,
            coste_adicional: producto.coste_adicional,
        }));

        const totalCosteAdicional = productos.reduce((total, producto) => total + producto.coste_adicional, 0);
        const totalAPagar = parseInt(precioTotal(), 10) + parseInt(costoEnvio, 10) + totalCosteAdicional;

        localStorage.setItem('paymentData', JSON.stringify({
            nombre,
            rut,
            telefono,
            correo,
            direccion,
            comuna: selectedComuna,
            region: { id: selectedRegion.id, nombre: selectedRegion.nombre },
            precio_total: precioTotal(),
            precio_comuna: costoEnvio,
            costo_adicional: totalCosteAdicional,
            total_pagar: totalAPagar,
            productos: productos,
            medioPago: medioPago,
        }));

        // Si el medio de pago es "Mercado Pago"
        if (medioPago === 'Mercado Pago') {
            try {
                const response = await fetch('https://onloop.cl/api/mercadoPago/mercado_pago.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        title: 'Compra Onloop',
                        price: totalAPagar,
                        quantity: 1,
                    }),
                });

                const result = await response.json();

                if (result.init_point) {
                    // Redirige a la URL de Mercado Pago
                    window.location.href = result.init_point;
                } else {
                    setMensajeError('Error al generar la preferencia de pago.');
                }
            } catch (error) {
                setMensajeError('Error de conexión con Mercado Pago.');
                console.error('Error:', error);
            }
        } else {
            // Vacía el carrito una vez que la compra ha sido confirmada y navega a la página de espera
            vaciarCarrito();
            window.location.href = '/espera';
        }
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value); // Actualiza el valor del reCAPTCHA cuando cambia
    };

    return (
        <React.Fragment>
            <Typography variant="h2" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
                Seleccione su medio de pago
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 2,
                    mx: 'auto',
                    maxWidth: 400,
                    width: '100%'
                }}
            >
                <Select
                    value={medioPago}
                    onChange={handleMedioPagoChange}
                    displayEmpty
                    fullWidth
                    sx={{ minWidth: 200, mb: 2 }}
                >
                    <MenuItem value="" disabled>
                        Seleccione medio de pago
                    </MenuItem>
                    {mediosPago.map((medio) => (
                        <MenuItem key={medio.id} value={medio.nombre}>
                            {medio.nombre}
                        </MenuItem>
                    ))}
                </Select>

                {/* Componente reCAPTCHA */}
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', maxWidth: '400px', margin: '0 auto', mb: 2 }}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lf-9FQqAAAAAPpx7_0rhH8lm1tmjAtm8oeyk32g"
                        onChange={handleRecaptchaChange} // Asegúrate de manejar el cambio de reCAPTCHA
                    />
                </Box>

                <Button
                    onClick={handlePayClick}
                    disabled={!medioPago || !recaptchaValue} // Habilita el botón solo si hay medio de pago y el reCAPTCHA está completado
                    variant="contained"
                    sx={{ backgroundColor: '#ff4d4d', '&:hover': { backgroundColor: '#e03e3e' },width: '100%' }}
                >
                    Pagar
                </Button>

                {/* Mostrar mensajes de error y mensajes adicionales */}
                {mensajeError && (
                    <Typography variant="h6" color="error" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {mensajeError}
                    </Typography>
                )}
                {mensajeAdicional && (
                    <Typography variant="h6" sx={{ mt: 2, whiteSpace: 'pre-wrap' }}>
                        {mensajeAdicional}
                    </Typography>
                )}
            </Box>
        </React.Fragment>
    );
};

export default SeleccionMedioPago;
