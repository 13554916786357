// App.js
import React, { useEffect } from 'react';
import './main.css';
import Navbar from './Componentes/Navbar';
import ItemListContainer from './Componentes/Productos/ItemListContainer';
import ItemDetailContainer from './Componentes/Productos/ItemDetailContainer';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Nosotros from './Componentes/Nosotros';
import Contacto from './Componentes/Contacto';
import { CartProvider } from './Context/CardContext';
import Inicio from './Componentes/Inicio/Inicio';
import Footer from './Componentes/Footer';
import ConfirmacionCompra from './Componentes/Compra/ConfirmacionCompra';
import Login from './Componentes/Login';
import Blog from './Componentes/Blog';
import { UserProvider } from './Context/UserContext';
import ScrollToTop from './Utils/ScrollToTop';
import TermsOfService from './Componentes/Policy/TermsOfService';
import PrivacyPolicy from './Componentes/Policy/PrivacyPolicy';
import ReactGA from 'react-ga4';
// import ConsentBanner from './Componentes/ConsentBanner'; // Importa el banner de consentimiento

// Importar las páginas de resultados de Mercado Pago
import Aprobado from './Componentes/Resultado/MercadoPago/Aprobado';
import Fallido from './Componentes/Resultado/MercadoPago/Fallido';
import Pendiente from './Componentes/Resultado/MercadoPago/Pendiente';
// import Datos from './Componentes/Datos';

// Hook para rastrear el cambio de rutas
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
}

function ProtectedRoute({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const transactionId = urlParams.get('transaction_id');

  useEffect(() => {
    if (!transactionId) {
      navigate('/');
    }
  }, [transactionId, navigate]);

  return children;
}

function AppRoutes() {
  // Coloca el hook aquí dentro del contexto de BrowserRouter
  usePageTracking();

  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/item/:id" element={<ItemDetailContainer />} />
      <Route path="/productos" element={<ItemListContainer />} />
      <Route path="/nosotros" element={<Nosotros />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/login" element={<Login />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/confirmacion" element={<ConfirmacionCompra />} />
      <Route path="/iniciar-sesion" element={<Login />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/confirmacion-compra" element={<Aprobado />} />
      <Route path="/error-compra" element={<Fallido />} />
      <Route path="/pending-compra" element={<Pendiente />} />
    </Routes>
  );
}

function App() {
  return (
    <div>
      <UserProvider>
        <CartProvider>
          <BrowserRouter>
            <ScrollToTop />
            {/* <Datos></Datos> */}
            <Navbar />
            <AppRoutes />
            <Footer />
            {/* <ConsentBanner /> */}
          </BrowserRouter>
        </CartProvider>
      </UserProvider>
    </div>
  );
}

export default App;
