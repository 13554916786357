import React, { useState } from 'react';  // Importa React y useState para el manejo de estado
import styled from 'styled-components';  // Importa styled-components para estilizar los componentes
import { Link, useLocation } from 'react-router-dom';  // Importa Link para navegación y useLocation para obtener la ruta actual
import IconButton from '@mui/material/IconButton';  // Importa IconButton de Material-UI
import Button from '@mui/material/Button';  // Importa Button de Material-UI
import Drawer from '@mui/material/Drawer';  // Importa Drawer (cajón) de Material-UI
import Box from '@mui/material/Box';  // Importa Box de Material-UI
import Grid from '@mui/material/Grid';  // Importa Grid de Material-UI
import CloseIcon from '@mui/icons-material/Close';  // Importa icono de cerrar
import Divider from '@mui/material/Divider';  // Importa Divider (línea divisoria) de Material-UI
import List from '@mui/material/List';  // Importa List de Material-UI
import ListItem from '@mui/material/ListItem';  // Importa ListItem de Material-UI
import ListItemButton from '@mui/material/ListItemButton';  // Importa ListItemButton de Material-UI
import ListItemText from '@mui/material/ListItemText';  // Importa ListItemText de Material-UI
import MenuIcon from '@mui/icons-material/Menu';  // Importa icono de menú
import Carito from './Compra/Carito';  // Importa el componente Carito (probablemente un carrito de compras)
import Onloop from '../Image/ONLOOPLOGO.svg';  // Importa el logo de OnLoop
import carritoImg from '../Image/CARRITO.svg';  // Importa el icono de carrito
import { Typography } from '@mui/material';
// import usuarioImg from '../Image/usuario.svg';  // Importa el icono de usuario

// Estilo para el contenedor de navegación
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #333;
  color: white;
`;

// Estilo para el botón del logo
const LogoButton = styled(Link)`
  height: 95px;
  background-image: url(${Onloop});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
`;

// Estilo para el botón de cancelar (cerrar menú)
const CancelButton = styled(Button)`
  && {
    color: white;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el menú de navegación (oculto en pantallas pequeñas)
const Menu = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Estilo para los ítems del menú (botones)
const MenuItem = styled(Button)`
  && {
    color: white;
    margin: 0 1rem;
    text-transform: none;
    font-size: 20px;
  }
`;

// Estilo para el icono de menú (hamburguesa) (visible en pantallas pequeñas)
const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: auto; /* Alinea el icono de menú a la izquierda */

  @media (max-width: 768px) {
    display: block;
  }
`;

// Estilo para el contenedor de los iconos (usuario y carrito)
const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Navbar = ({ fixed }) => {
  const location = useLocation();  // Obtiene la ubicación actual de la ruta
  const [isOpen, setIsOpen] = useState(false);  // Estado para el estado del cajón del carrito
  const [menuOpen, setMenuOpen] = useState(false);  // Estado para el estado del cajón del menú

  // Función para abrir o cerrar el cajón del carrito
  const toggleDrawer = (newOpen) => () => {
    setIsOpen(newOpen);
  };

  // Función para abrir o cerrar el cajón del menú
  const toggleMenuDrawer = (newOpen) => () => {
    setMenuOpen(newOpen);
  };

  // Verifica si la página actual es la de confirmación
  const isConfirmationPage = location.pathname === '/confirmacion';

  // Componente para el cajón del carrito
  const CarritoComponent = (
    <Box sx={{ width: 250 }} role="presentation">
      <Grid container style={{ textAlign: 'center', justifyContent: 'space-between', padding: '1.3rem' }}>
        <Typography
            variant="h3"
            sx={{
              fontSize: '1.5rem',
              fontFamily: 'Outfit, sans-serif',
              fontWeight: 'bold',
              marginBottom: '1rem', // Agrega margen inferior para separar del contenido siguiente
            }}
          >
            Tu carro
          </Typography>        
        <Button onClick={toggleDrawer(false)} sx={{ color: 'black' }}><CloseIcon /></Button>
      </Grid>
      <Divider />
      <Carito toggleDrawer={toggleDrawer} />
    </Box>
  );

  // Componente para la lista de navegación en el cajón del menú
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleMenuDrawer(false)}>
      <List>
        {['productos', 'nosotros', 'contacto', 'blog'].map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={`/${text}`}>
              <ListItemText primary={text.charAt(0).toUpperCase() + text.slice(1).replace('-', ' ')} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider/>
    </Box>
  );

  return (
    <Nav fixed={fixed}>
      {/* Renderiza un diseño diferente si estamos en la página de confirmación */}
      {isConfirmationPage ? (
        <>
          <LogoButton 
            onClick={() => window.location.href = '/'} 
          />
            <CancelButton
              variant="outlined"
              onClick={() => window.location.href = '/'}
              sx={{
                fontFamily: 'Outfit, sans-serif', // Aplica la familia de fuente
                fontWeight: 400,                  // Peso de la fuente
                textAlign: 'center',              // Alineación del texto
                textJustify: 'inter-word',        // Justificación del texto
              }}
            >
              Seguir comprando
            </CancelButton>
        </>
      ) : (
        <>
          <Hamburger onClick={toggleMenuDrawer(true)}>
            <MenuIcon style={{ color: 'white' }} />
          </Hamburger>
            <LogoButton 
              onClick={() => window.location.href = '/'} 
            />
          <Menu>
            {/* Menú de navegación visible en pantallas grandes */}
            <MenuItem 
              component={Link} 
              to="/productos" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px', 
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Productos
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/nosotros" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Nosotros
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/contacto" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Contacto
            </MenuItem>

            <MenuItem 
              component={Link} 
              to="/blog" 
              sx={{ 
                fontFamily: 'Outfit, sans-serif', 
                fontWeight: 400, 
                color: 'black', 
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#ff4d4d', 
                  color: 'white'
                },
                textDecoration: 'none'
              }}
            >
              Blog
            </MenuItem>

          </Menu>
          <IconContainer>
            {/* Iconos para usuario y carrito */}
            {/* <IconButton component={Link} to="/iniciar-sesion" style={{ color: 'white' }}>
              <img src={usuarioImg} alt="Iniciar Sesión" style={{ width: '30px', height: '30px' }} />
            </IconButton> */}
            <IconButton
              onClick={toggleDrawer(true)}
              style={{ color: 'black', padding: 8 }}
              aria-label="Carrito"
            >
              <img src={carritoImg} alt="Carrito" style={{ width: '30px', height: '30px' }} />
            </IconButton>
          </IconContainer>
          {/* Cajón del carrito */}
          <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
            {CarritoComponent}
          </Drawer>
          {/* Cajón del menú */}
          <Drawer anchor="left" open={menuOpen} onClose={toggleMenuDrawer(false)}>
            {DrawerList}
          </Drawer>
        </>
      )}
    </Nav>
  );
};

export default Navbar;  // Exporta el componente Navbar para usarlo en otras partes de la aplicación
