import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';  // Importa componentes y hooks de Material-UI
import React from 'react';  // Importa React
import { Link } from 'react-router-dom';  // Importa el componente Link para navegación
import IsotipoOnLoop from '../Image/Footer/ONLOOP_ISOTIPO.svg';  // Importa el logo de OnLoop
import TictokLogo from '../Image/Footer/tiktok.svg';  // Importa el logo de Facebook
import FacebookLogo from '../Image/Footer/facebook.svg';  // Importa el logo de Facebook
import InstagramLogo from '../Image/Footer/instagram.svg';  // Importa el logo de Instagram
import MercadoPagoLogo from '../Image/Footer/mercadopago.svg';  // Importa el logo de Mercado Pago

const Footer = () => {
  const theme = useTheme();  // Obtiene el tema actual de Material-UI
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));  // Verifica si la pantalla es pequeña

  return (
    <Box mt={5} py={3} px={2} bgcolor="#333" color="white" boxShadow={2}>
      {/* Contenedor principal del footer con margen, relleno, color de fondo y sombra */}
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {/* Contenedor de grid con espaciado, alineación y justificación de contenido */}
        <Grid item xs={12} sm={3} textAlign={isSmallScreen ? 'center' : 'left'}>
          {/* Primer ítem de grid, ocupa 12 columnas en pantallas pequeñas y 3 en pantallas más grandes */}
          <Box mb={isSmallScreen ? 2 : 0} mx={isSmallScreen ? 0 : 8}>
            {/* Contenedor para el logo de OnLoop con margen en pantalla pequeña */}
            <img src={IsotipoOnLoop} alt="OnLoop Logo" height="100" />
          </Box>
          <Box mt={2} textAlign={isSmallScreen ? 'center' : 'left'} mx={isSmallScreen ? 0 : 8}>
          {/* Contenedor para los íconos de redes sociales */}
          <a href="https://www.tiktok.com/@onloop.cl" target="_blank" rel="noopener noreferrer">
            <img src={TictokLogo} alt="Tictok" height="40" style={{ marginRight: '8px' }} />
          </a>
          <a href="https://web.facebook.com/people/Onloop/61565829035656/" target="_blank" rel="noopener noreferrer">
            <img src={FacebookLogo} alt="Facebook" height="40" style={{ marginRight: '8px' }} />
          </a>
          <a href="https://www.instagram.com/onloop.cl/" target="_blank" rel="noopener noreferrer">
            <img src={InstagramLogo} alt="Instagram" height="40" />
          </a>
        </Box>
        </Grid>
        <Grid item xs={12} sm={3} textAlign="center">
          <Typography
              variant="h6"
              gutterBottom
              sx={{
                mt: 1,
                fontFamily: 'Outfit, sans-serif', // Asegúrate de que la fuente Outfit esté disponible
                fontWeight: 700 // Negrita
              }}
            >
            Información
            </Typography>
          {/* Título de sección "Información" */}
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400
              }}
            >
              Central Gonzalo Pérez Llona 1255 <br />
              Maipú, Región Metropolitana
            </Typography>

          {/* Dirección física de la empresa */}
        </Grid>
        <Grid item xs={12} sm={3} textAlign="center">
          {/* Tercer ítem de grid, con alineación de texto centrada */}
          <Typography
              variant="h6"
              gutterBottom
              sx={{
                mt: 1,
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 700
              }}
            >
            Contáctanos
            </Typography>
          {/* Título de sección "Contáctanos" */}
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 400
              }}
            >
            contacto@onloop.cl<br />
              {/* ventas@onloop.cl<br /> */}
              {/* +56987654321<br />
              +56912345678 */}
            </Typography>
          {/* Información de contacto: correos electrónicos y números telefónicos */}
        </Grid>
        <Grid item xs={12} sm={3} textAlign={isSmallScreen ? 'center' : 'right'}>
          {/* Cuarto ítem de grid, con alineación de texto a la derecha en pantallas grandes y al centro en pantallas pequeñas */}
          <Box mx={isSmallScreen ? 0 : 8}>
            {/* Contenedor para la sección de medios de pago */}
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                mt: 1,
                fontFamily: 'Outfit, sans-serif',
                fontWeight: 700
              }}
            >
              Medios de Pago
            </Typography>
            <Box display="flex" justifyContent={isSmallScreen ? 'center' : 'flex-end'}>
              {/* Contenedor para los íconos de medios de pago */}
              {/* <Box mx={1}>
                <img src={TarjetaLogo} alt="Tarjeta" height="50" />
              </Box>
              <Box mx={1}>
                <img src={VisaLogo} alt="Visa" height="50" />
              </Box> */}
              <Box>
                <img
                  src={MercadoPagoLogo}
                  alt="Mercado Pago"
                  style={{ maxWidth: '150px', maxHeight: '150px', width: 'auto', height: 'auto' }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="body2" mt={3} textAlign="center">
        {/* Texto con derechos reservados y enlaces a términos y política de privacidad */}
        © 2024 OnLoop. All rights reserved.
        {isSmallScreen ? (
          <>
            <br />
            <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
            Terminos y condiciones
            </Link>
            {/* <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
              Terms of Service
            </Link>
            {' | '}
            <Link to="/privacy" style={{ color: 'inherit', textDecoration: 'none' }}>
              Privacy Policy
            </Link> */}
          </>
        ) : (
          <span style={{ marginLeft: '16px' }}>
            <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
            Terminos y condiciones
            </Link>
            {/* {' | '} */}
            {/* <Link to="/privacy" style={{ color: 'inherit', textDecoration: 'none' }}>
              Privacy Policy
            </Link> */}
          </span>
        )}
      </Typography>
      {!isSmallScreen && (
        <Typography variant="body2" style={{ marginTop: '8px' }} textAlign="center">
          Hecho con ❤️ por Onloop
        </Typography>
      )}
    </Box>
  );
};

export default Footer;  // Exporta el componente Footer para usarlo en otras partes de la aplicación
